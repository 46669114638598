import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0040 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="看護休暇とはどんな制度？取得条件や休暇日数など解説します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.12.27</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        看護休暇とは、幼い子供を育てる労働者が取得することのできる非常にありがたい休暇制度です。ワーク・ライフ・バランスや働き方改革を受けて、各企業には育児や介護との両立を図りながら働く労働者への深い理解が求められていますが、この看護休暇もその重要な制度の一つになります。
        <br />
        今回は、看護休暇とはどのような制度なのか、取得対象やケース、取得単位、そして気になる給与の取扱いなどについて、順を追って解説しましょう。
      </p>
      <img src="/images/column/details/c0040.jpg" alt="キンクラコラム画像40" />
      <h1>「看護休暇」とは</h1>
      <p>
        看護休暇とは、小学校へ就学する前の子供を育てている労働者が、その子供が怪我や病気などの事情で付き添いや世話が必要となった場合に、
        <span>看護をするために取得</span>
        することのできる休暇です。看護の対象には、前述の通り子供の怪我や病気を理由としたケースに加え、健康診断の受診や予防接種の付き添いも含まれます。
        <br />
        <br />
        看護休暇は育児・介護休業法で定められた法律に基づく休暇で、すべての労働者へ付与される有給休暇とは別の休暇として付与する必要があります。
        <br />
        似たような意味合いの制度に<span>「育児時間」</span>
        というものがありますが、これは育児・介護休業法ではなく労働基準法による制度で、1歳に満たない子を育てる女性労働者が、一日あたり2回まで、それぞれ30分ほどの時間帯を、子を育てるための時間帯として請求することができる制度です。
        <br />
        <br />
        看護休暇制度が導入されるに至った背景としては、まず
        <span>仕事と育児を両立させる労働者が増加</span>
        したことによります。特に小さな子供はふとしたことで体調を崩すケースが多く、急な発熱や怪我などの体調不良となった場合は、保護者が世話をしなければならない状況になります。このような状況でやむを得ず働くことのできない労働者をサポートするため、看護休暇が設けられる運びとなりました。なお、要介護状態となっている家族の介護が必要な労働者に向けた
        <span>「介護休暇」</span>制度も同様に定められています。
      </p>
      <h1>看護休暇を取得できるケースとは</h1>
      <p>
        看護休暇制度の対象となるのは、雇用形態や性別を問わず、
        <span>小学校就学前の子を養育するすべての労働者</span>です。
        <br />
        ただし、日雇い労働者は除外される点に注意しましょう。また、継続雇用期間が半年に満たない労働者や1週間あたりの所定労働時間が2日以下の労働者については、労使協定を締結することで看護休暇の対象から除外することも可能です。労使協定により除外された労働者については、看護休暇を申し出た場合でも取得することはできません。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1>看護休暇の取得単位</h1>
      <p>
        看護休暇の取得日数は、<span>1年度間につき5日</span>
        が原則です。小学校就学前の子供が2人以上いる場合については、その倍となる10日が限度となります。つまり、子供が3人以上いる場合でも取得日数は一律で10日です。ただし、この10日は子供の日数で按分する必要はなく、複数の子がいる労働者が1人の子供のために看護休暇をまるまる10日使うことも認められています。
        <br />
        会社側は、労働者が看護休暇の取得を申し出た場合は、たとえ
        <span>
          繁忙期で人が抜けることが困る状況の場合でも休暇を変更する権利はありません
        </span>
        。これは、当然ながら、看護が必要な子供の世話をする日にちを変更することは不可能であるためです。
        <br />
        <br />
        具体的な取得単位は、1日単位から1時間単位まで、幅広いケースによる取得が可能です。これまでは、1日あたりの所定労働時間が4時間以下である労働者は取得できない決まりがありましたが、令和3年以降は法改正により、すべての労働者が取得できるようになりました。
        <br />
        取得については、原則としてまとまった時間で取得することになりますが、中には昼前後だけ看護の時間が必要となる場合のいわゆる「中抜け」が求められる場合があります。中抜けを認めるか否かについては法律による決まりがなく、会社側の判断にゆだねられます。
        <br />
        ただし、これまでは中抜けを認めていた会社が「今後は認めない」という制度変更を行うことは、
        <span>労働者の不利益変更に該当することから認められない</span>
        点に注意しましょう。また、これまでは半日単位や時間単位を認めていた会社が「今後は認めない」と制度変更を行うことも認められません。
      </p>
      <h1>看護休暇の規程</h1>
      <p>
        看護休暇を取得させるためには、前もって就業規則などで看護休暇に対する制度を定めておく必要があります。
        <br />
        看護休暇制度を定める場合は、まずは看護休暇の対象となる労働者の範囲を決定します。すべての労働者を対象とする場合は、まず対象労働者と休暇日数などの制度概要、たとえば
        <span>4月1日から翌年3月31日まで</span>、という適用期間を記載します。
        <br />
        次の段階として、看護休暇取得時の給与の扱いを定めます。給与の扱いについては中抜けの制度と同様に法律上の決まりがないことから、有給・無給の判断は会社側の判断にゆだねられます。もしも無給とする場合は、
        <span>労務管理上では他の欠勤と別の項目で管理</span>をしましょう。
        <br />
        <br />
        また、労使協定を締結し一定の労働者を除外する場合については、通常の規定に加え、労使協定により除外された労働者による申し出は拒むことが可能である旨を記載します。
      </p>
      <h1>まとめ</h1>
      <p>
        看護休暇の取得を申し出た労働者に対する不利益取扱いは固く禁じられています。中には査定に影響するのではないかと躊躇する労働者が少なくありませんが、看護休暇は労働者が取得している正式な権利であることを伝え、安心して取得してもらう環境づくりを整えましょう。
        <br />
        なお、法定の規定を上回る内容で看護休暇制度を定めた場合は、両立支援助成金の
        <span>「育児休業等支援コース」</span>
        の対象となる可能性があります。たとえば、看護休暇取得時間帯を有給とするケースなどがこれに該当しますので、一度検討してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0040
